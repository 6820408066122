import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';

// const {
//   initialState: state,
//   formDataInputs,
//   step,
// } = storeToRefs(useAppStore());

/*
Parametros para generar los items dinamicos de select y autocomplete
del servicio /formadmission?lang=es
1-Param Nombre del objecto en mayusculas
2-param nombre del store del input
3-Propiedad de la data a mostrar en el text del item
4-Propiedad de la data para el value del item
*/
export default function useItems(
  key: string,
  store: string,
  textValue: string,
  valueItem: string,
  valueInTex = false,
  idx: string = 'false',
) {
  const appStore = useAppStore(); // Debe estar dentro de la función
  const { initialState: state, formDataInputs } = storeToRefs(appStore);

  const itemsOptions = ref([]);

  watch(
    () => formDataInputs.value[key as keyof typeof formDataInputs.value],
    (newValue: any) => {
      if (newValue) {
        const arrNac = newValue.map((v: any) => {
          if (v.NATIO !== '') {
            if (valueInTex) {
              return { text: `${v[textValue].toUpperCase()} - ${v[valueItem].toUpperCase()}`, value: v[valueItem] };
            }
            if (idx === 'entidadEducativa') {
              return { text: `${v[textValue].toUpperCase()}`, value: v[valueItem], slart: v.SLART };
            }
            return { text: `${v[textValue].toUpperCase()}`, value: v[valueItem] };
          }
          return { text: '', value: '' };
        }).filter((elm: any) => elm.text !== '');

        arrNac.sort((a: any, b: any) => {
          const nameA = a.text; // ignore upper and lowercase
          const nameB = b.text; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });
        itemsOptions.value = arrNac;

        const propertyValue: any = state.value[store as keyof typeof state.value];
        if (propertyValue && typeof propertyValue === 'object' && 'value' in propertyValue) {
          const nacionalidad: {
            text: string;
            value: any;
          }[] = arrNac.filter((
            item: { text: string; value: string },
          ) => item.value === propertyValue.value);
          if (nacionalidad[0].text !== propertyValue.text) {
            propertyValue.text = nacionalidad[0].text;
          }
        } else if (propertyValue && typeof propertyValue === 'object' && idx) {
          for (let index = 0; index < propertyValue.length; index += 1) {
            const element = propertyValue[index];
            if (element[idx] && idx !== 'false') {
              if ('text' in element[idx] && 'value' in element[idx]) {
                const propertyDyn: {
                  text: string;
                  value: any;
                }[] = arrNac.filter((
                  item: { text: string; value: string },
                ) => item.value === element[idx].value);
                if (propertyDyn.length > 0 && propertyDyn[0].text !== element[idx].text) {
                  element[idx].text = propertyDyn[0].text;
                }
                // else if ((
                // step.value === 2 &&
                // ['titulo', 'pais', 'entidadEducativa', 'idioma'].includes(idx))
                // || (step.value === 3
                // && !['titulo', 'pais', 'entidadEducativa', 'idioma'].includes(idx)))
                // element[idx] = null;
              }
            }
          }
        }
      }
    },
    { immediate: true },
  );

  return itemsOptions;
}
