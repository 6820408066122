// Utilities
import { defineStore } from 'pinia';
import httpApi from '@/api';
import Swal from 'sweetalert2';
import { FormDataInputs } from '@/types';

export interface ResponseApi {
  message: string;
  colaborador: string;
  pernr: number;
  success: boolean;
}

interface TipoID { text: string, value: string }

type FechaMode = 'year' | 'month' | 'months';

type ItemsValues = { text?: string, value?: string | number };

export const useAppStore = defineStore('app', {
  state: () => ({
    locale: 'es',
    onlyCV: false,
    loading: false,
    step: 1,
    responseApi: <ResponseApi>{ },
    verifyData: {
      vorna: '',
      nachn: '',
      icnum: '',
      gbdat: '',
      email: '',
      appName: '',
    },
    candidatoVerify: false,
    formDataInputs: <FormDataInputs>[],
    initialState: {
      tipoID: null as TipoID | null,
      identificacion: '',
      identificacionMSK: '',
      identificationActive: true,
      nombre: '',
      primerApellido: '',
      segundoApellido: '',
      estatusResidencia: null,
      fechaVigencia: null as Date | null,
      fechaVigencia_format: null as string | null,
      fechaVigecia_month: -200,
      menuVigencia: false,
      tieneCorreo: true,
      email: '',
      fechaNacimiento: null as Date | null,
      fechaNacimiento_format: null as string | null,
      fechaNacimiento_month: 0,
      menu: false,
      menuLicencia: false,
      fechaMode: <FechaMode>'year',
      tratamiento: null,
      celular: null,
      estadoCivil: null,
      cantidadHijos: null,
      nacionalidad: null as ItemsValues | null,
      genero: null,
      paisNacimiento: null,
      lugarNacimiento: null as ItemsValues | null,
      otroLugarNacimiento: null,
      gpcTrabajado: false,
      licencia: false,
      categoriaLicencia: null,
      fechaVigenciaLicencia: null as Date | null,
      fechaVigenciaLicencia_format: null as string | null,
      rotativo: false,
      movilidad: false,
      comoLlegaste: null as ItemsValues | null,
      comoLlegasteOtro: null,
      tieneFamiliar: false,
      familiarNombre: null,
      referido: false,
      referidoNombre: null,
      interes: null,
      vacantes: null,
      archivoCV: null as string | null,
      direccion: {
        pais: null,
        ciudad: '',
        otra_provincia: '',
        provincia: null as ItemsValues | null,
        sector: '',
        calle: '',
        numero: '',
        tel_residencial: null,
      },
      educacion: [
        {
          tipoEducacion: null as ItemsValues | null,
          otroTipoEducacion: '',
          mesInicio: null as ItemsValues | null,
          fechaInicio: null,
          titulo: null as ItemsValues | null,
          otroTitulo: '',
          entidadEducativa: null as ItemsValues | null,
          entidadOptions: [],
          OtraEntidadEducativa: '',
          pais: null,
          estatus: null as ItemsValues | null,
          mesFin: null as ItemsValues | null,
          fechaFin: null,
        },
      ],
      idiomas: [
        {
          idioma: null as ItemsValues | null,
          nivel: null as ItemsValues | null,
        },
      ],
      expLaboral: true,
      experienciaLaboral: [
        {
          empresa: null,
          mesInicio: null as ItemsValues | null,
          fechaInicio: null,
          telefono: null,
          pais: null,
          provincia: null as ItemsValues | null,
          provinciaItems: [],
          otraProvincia: '',
          posicion: null as ItemsValues | null,
          otraPosicion: '',
          sectorEmpresarial: null as ItemsValues | null,
          otroSectorEmpresarial: '',
          activo: false,
          razonSalida: null as ItemsValues | null,
          mesSalida: null as ItemsValues | null,
          fechaSalida: null,
          rangoSalario: null,
          supervisor: null,
        },
      ],
    },
  }),
  getters: {
    getLocale: (state) => state.locale,
    getResponseApi: (state) => state.responseApi,
    getformDataInputs: (state) => state.formDataInputs,
  },
  actions: {
    setLocale(current: string): string {
      this.locale = current === 'es' ? 'en' : 'es';
      return this.locale;
    },
    setArchivoCV(base64: string) {
      this.initialState.archivoCV = base64.replace('data:application/pdf;base64,', '');
    },
    async uploadOnlyDocument(base64: string) {
      this.loading = true;
      try {
        const appName = import.meta.env.VITE_APP_NAME;

        const data = {
          tipoId: this.initialState.tipoID,
          identificacion: this.initialState.identificacion,
          email: this.initialState.email,
          pernr: this.responseApi.pernr,
          document: base64.replace('data:application/pdf;base64,', ''),
          appName,
        };

        const api = await httpApi();
        const resp = await api.post('api/candidato/document', data);
        const result = resp.data;

        if (result.success && result.message.length > 0) {
          Swal.fire({
            text: result.message,
            icon: 'info',
          });
        }

        return true;
      } catch (error: any) {
        // console.log(error);
        Swal.fire({
          text: 'Error volver a intentar más tarde.',
          icon: 'error',
        });
        throw new Error('Error al subir el archivo.', error);
      } finally {
        this.loading = false;
      }
    },
    async verifyCandidato() {
      this.loading = true;
      const appName = import.meta.env.VITE_APP_NAME;
      try {
        const fechaNacimiento = this.initialState.fechaNacimiento ? this.initialState.fechaNacimiento : '';
        this.verifyData.vorna = window.btoa(this.initialState.nombre || '');
        this.verifyData.nachn = window.btoa(this.initialState.primerApellido || '');
        this.verifyData.icnum = window.btoa(this.initialState.identificacion || '');
        this.verifyData.email = window.btoa(this.initialState.email || '');
        this.verifyData.gbdat = window.btoa(fechaNacimiento.toString().slice(0, 10).replace(/\D/g, ''));
        this.verifyData.appName = appName;

        const api = await httpApi();
        const resp = await api.post('api/candidato/verify', this.verifyData);
        this.responseApi = resp.data;

        if (!this.responseApi.success && this.responseApi.message.length > 0) {
          Swal.fire({
            text: this.responseApi.message,
            icon: 'info',
          });
          if (this.responseApi.colaborador) return;
          // Activar solo subir CV
          this.onlyCV = false;
          return;
        }
        this.candidatoVerify = true;
      } catch (error: any) {
        // console.log(error);
        Swal.fire({
          text: 'Error volver a intentar más tarde.',
          icon: 'error',
        });
        // throw new Error('Error servicio de verificacion', error);
      } finally {
        this.loading = false;
      }
    },
    async getDataForm(lang: string) {
      this.loading = true;
      try {
        const appName = import.meta.env.VITE_APP_NAME;
        const api = await httpApi();
        const resp = await api.get(`api/formadmission?lang=${lang}&appName=${appName}`);
        this.formDataInputs = resp.data;
      } catch (error: any) {
        // console.log('Error servicio al popular los input', error);
        // throw new Error('Error servicio al popular los input', error);
      } finally {
        this.loading = false;
        setTimeout(() => {
        }, 8000);
      }
    },
    async getProvinciaPais(pais: string): Promise<any> {
      this.loading = true;
      try {
        const appName = import.meta.env.VITE_APP_NAME;
        const api = await httpApi();
        const resp = await api.get(`api/formadmission?lang=${this.locale}&land=${pais}&appName=${appName}`);
        const { PROVINCIA } = resp.data;

        const arrCiudad = PROVINCIA.map(
          (v: any) => ({ text: v.BEZEI.toUpperCase(), value: v.BLAND }),
        );

        arrCiudad.sort((a: any, b: any) => {
          const nameA = a.text; // ignore upper and lowercase
          const nameB = b.text; // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        });

        return arrCiudad;
      } catch (error: any) {
        // console.log('Error servicio al popular input Lugar nacimiento', error);
        // throw new Error('Error servicio al popular input Lugar nacimiento', error);
        return null;
      } finally {
        this.loading = false;
      }
    },
    addEducacion() {
      if (this.initialState.educacion.length < 10) {
        this.initialState.educacion.push({
          tipoEducacion: null as ItemsValues | null,
          otroTipoEducacion: '',
          mesInicio: null as ItemsValues | null,
          fechaInicio: null,
          titulo: null as ItemsValues | null,
          otroTitulo: '',
          entidadEducativa: null as ItemsValues | null,
          entidadOptions: [],
          OtraEntidadEducativa: '',
          pais: null,
          estatus: null as ItemsValues | null,
          mesFin: null as ItemsValues | null,
          fechaFin: null,
        });
      }
    },
    addIdioma() {
      if (this.initialState.idiomas.length < 10) {
        this.initialState.idiomas.push({
          idioma: null as ItemsValues | null,
          nivel: null as ItemsValues | null,
        });
      }
    },
    addExpLaboral() {
      if (this.initialState.experienciaLaboral.length < 10) {
        this.initialState.experienciaLaboral.push({
          empresa: null,
          mesInicio: null as ItemsValues | null,
          fechaInicio: null,
          telefono: null,
          pais: null,
          provincia: null as ItemsValues | null,
          provinciaItems: [],
          otraProvincia: '',
          posicion: null as ItemsValues | null,
          otraPosicion: '',
          sectorEmpresarial: null as ItemsValues | null,
          otroSectorEmpresarial: '',
          activo: false,
          razonSalida: null as ItemsValues | null,
          mesSalida: null as ItemsValues | null,
          fechaSalida: null,
          rangoSalario: null,
          supervisor: null,
        });
      }
    },
    delExpLaboral(idx: number) {
      if (this.initialState.experienciaLaboral.length > 1) {
        this.initialState.experienciaLaboral.splice(idx, 1);
      }
    },
    delEducacion(idx: number) {
      if (this.initialState.educacion.length > 1) {
        this.initialState.educacion.splice(idx, 1);
      }
    },
    delIdioma(idx: number) {
      if (this.initialState.idiomas.length > 1) {
        this.initialState.idiomas.splice(idx, 1);
      }
    },
    async postCandidato() {
      this.loading = true;
      if (this.initialState.archivoCV) {
        const {
          tipoID,
          identificacion,
          estatusResidencia,
          fechaVigencia,
          nombre,
          primerApellido,
          segundoApellido,
          celular,
          email,
          tratamiento,
          fechaNacimiento,
          genero,
          paisNacimiento,
          lugarNacimiento,
          gpcTrabajado,
          movilidad,
          rotativo,
          referido,
          referidoNombre,
          tieneCorreo,
          comoLlegaste,
          comoLlegasteOtro,
          nacionalidad,
          interes,
          categoriaLicencia,
          fechaVigenciaLicencia,
          cantidadHijos,
          vacantes,
          direccion,
          estadoCivil,
          otroLugarNacimiento,
          familiarNombre,
          educacion,
          idiomas,
          expLaboral,
          archivoCV,
        } = this.initialState;

        const appName = import.meta.env.VITE_APP_NAME;
        const data = {
          infoCandidato: {
            appName,
            tipoId: tipoID,
            identificacion,
            estatus_residencia: estatusResidencia,
            fecha_residencia: fechaVigencia,
            nombre,
            primer_apellido: primerApellido,
            segundo_apellido: segundoApellido,
            celular,
            email,
            tratamiento,
            fecha_nacimiento: fechaNacimiento,
            genero,
            pais_nacimiento: paisNacimiento,
            lugar_nacimiento: lugarNacimiento,
            gpcTrabajado,
            movilidad,
            rotativo,
            referido,
            referido_nom: referidoNombre,
            tiene_correo: tieneCorreo,
            llegaste: comoLlegaste,
            llegaste_otro: comoLlegasteOtro,
            nacionalidad,
            interes,
            categoriaLicencia,
            vigenciaLicencia: fechaVigenciaLicencia,
            hijos: cantidadHijos,
            vacantes,
            direccion,
            estado_civil: estadoCivil,
            otroLugarNacimiento,
            familiar_nom: familiarNombre,
            educacion,
            idiomas,
            ExpLaboral: expLaboral,
            document: archivoCV,
          },
        };

        try {
          const api = await httpApi();
          const resp = await api.post('api/candidato/create', data);
          if (resp.data.success) {
            Swal.fire({
              text: resp.data.message,
              icon: 'success',
            }).then(() => {
              this.$reset();
            });
          } else {
            Swal.fire({
              title: resp.data.message,
              icon: 'error',
            });
          }
        } catch (error: any) {
          throw new Error('Error en el servicio de creacion.', error);
        } finally {
          this.loading = false;
          setTimeout(() => {
          }, 8000);
        }
      } else {
        const msg = 'Error en el archivo CV, volver intentar.';
        // eslint-disable-next-line no-console
        console.info(msg);
      }
    },
  },
  persist: true,
});

// export default useAppStore;
