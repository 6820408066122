<script setup lang="ts">
const model = defineModel();

defineProps([
  'label',
  'items',
  'required',
  'error',
  'errorMessages',
]);

</script>
<template>
  <v-autocomplete
    v-model="model"
    :label="label"
    :items="items"
    :required="required"
    :error="error"
    :error-messages="errorMessages"
    item-title="text"
    item-value="value"
    variant="outlined"
    density="compact"
    hide-details="auto"
    return-object
  />
</template>

<style scoped>

</style>
