<script setup lang="ts">
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';
import { useVuelidate } from '@vuelidate/core';
import * as validators from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';

// Composables
import useItems from '@/composables/useItems';
import useItemsGlobal from '@/composables/useItemsGlobal';
import useErrorMessagesDyn from '../composables/useErrorMessagesDyn';

const {
  tipoEducacionItems,
  mesesItems,
  nivelItems,
} = useItemsGlobal();

const { mdAndDown } = useDisplay();
const { t } = useI18n();

const {
  loading, initialState: state,
} = storeToRefs(useAppStore());

const {
  addEducacion, delEducacion, addIdioma, delIdioma,
} = useAppStore();

const rangoFechaOptions = computed(() => {
  const inicio = [];
  for (let i = 1970; i <= 2030; i += 1) {
    inicio.push(i);
  }
  return inicio;
});

const especializacioItems = useItems('FORMACIONES', 'educacion', 'ATEXT', 'AUSBI', false, 'titulo');
const entidades = useItems('INST_ACADEMICAS', 'educacion', 'INSTI', 'SCHCD', false, 'entidadEducativa');
const paisItems = useItems('PAISES', 'educacion', 'LANDX', 'LAND1', false, 'pais');
const estadoFormacionItems = useItems('ESTADO_FORMACION', 'educacion', 'STEXT', 'SLABS', false, 'titulo');
const idiomaItems = useItems('IDIOMA', 'idiomas', 'SPTXT', 'SPRSL', false, 'idioma');

const filtroEntidad = (index: number, _edu: any) => {
  state.value.educacion[index].entidadOptions = entidades.value
    .filter((ent: any) => ent.slart === _edu);
};

const { createI18nMessage } = validators;
const messagePath = ({ $validator, $params }: any) => {
  const fieldName = $params?.$field || $validator;
  return `$vuetify.validations.${fieldName}`;
};

const withI18nMessage = createI18nMessage({ t, messagePath });
const required = withI18nMessage(validators.required);
const requiredIf = (condition: any) => withI18nMessage(validators.requiredIf(condition));
const minLength = (length: number) => withI18nMessage(validators.minLength(length));
// const maxLength = (length: number) => withI18nMessage(validators.maxLength(length));
// const numeric = withI18nMessage(validators.numeric);

const rules = computed(() => ({
  educacion: {
    $each: validators.helpers.forEach({
      tipoEducacion: {
        required,
      },
      otroTipoEducacion: {
        requiredIf: requiredIf((_val: any, inputData: any) => _val.length === 0 && inputData.tipoEducacion && inputData.tipoEducacion.value === 'Z9'),
        minLength: minLength(5),
      },
      mesInicio: {
        required,
      },
      fechaInicio: {
        required,
      },
      titulo: {
        required,
      },
      entidadEducativa: {
        required,
      },
      otroTitulo: {
        requiredIf: requiredIf((_val: any, inputData: any) => _val.length === 0
                                            && inputData.titulo
                                            && inputData.titulo.value === 2320),
        minLength: minLength(5),
      },
      OtraEntidadEducativa: {
        requiredIf: requiredIf((_val: any, inputData: any) => _val.length === 0
                                            && inputData.entidadEducativa
                                            && inputData.entidadEducativa.value === 999),
        minLength: minLength(5),
      },
      pais: {
        required,
      },
      estatus: {
        required,
      },
      mesFin: {
        requiredIf: requiredIf((_val: any, inputData: any) => inputData.estatus
                                            && inputData.estatus.value === '93'),
      },
      fechaFin: {
        requiredIf: requiredIf((_val: any, inputData: any) => inputData.estatus
                                            && inputData.estatus.value === '93'),
      },
    }),
  },
  idiomas: {
    $each: validators.helpers.forEach({
      nivel: {
        requiredIf: requiredIf((_val: any, inputData: any) => inputData.idioma !== null),
      },
    }),
  },
}));

const v$ = useVuelidate(rules, state);

async function submitForm() {
  return v$.value.$validate();
}

defineExpose({
  submitForm,
});

</script>
<template>
  <v-form @submit.prevent="" ref="formFile" :disabled="loading">
    <v-sheet
      class="mb-5"
      v-for="(edu, index) in state.educacion"
      :key="index"
      border>
      <v-container class="pa-0">
        <v-row
          no-gutters
          :class="{ 'mb-10': mdAndDown }"
          justify="center"
          align="center"
        >
          <v-col class="text-center">
            <v-tooltip
              :text="$t('$vuetify.eliminar')"
              location="top"
            >
              <template v-slot:activator="{ props }">
                <v-btn @click="delEducacion(index)" v-bind="props" color="red-darken-2" density="compact" icon="fa fa-minus" />
              </template>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            md="11"
          >

            <v-row
              no-gutters
            >

              <v-col
                cols="12"
                md="4"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.tipoEducacion"
                    @update:modelValue="filtroEntidad(index, edu.tipoEducacion?.value)"
                    :label="$t('$vuetify.tipoEducacion')"
                    :items="tipoEducacionItems"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].tipoEducacion.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].tipoEducacion, 'tipoEducacion')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if=" edu.tipoEducacion && edu.tipoEducacion.value === 'Z9'"
              >
                <v-sheet class="ma-2">
                  <InputTextComponent
                    v-model.trim="edu.otroTipoEducacion"
                    :label="$t('$vuetify.otroTipoEducacion')"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].otroTipoEducacion.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].otroTipoEducacion, 'otroTipoEducacion')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.mesInicio"
                    :label="$t('$vuetify.mesInicio')"
                    :items="mesesItems"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].mesInicio.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].mesInicio, 'mesInicio')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.fechaInicio"
                    :label="$t('$vuetify.fechaInicio')"
                    :items="rangoFechaOptions"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].fechaInicio.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].fechaInicio, 'fechaInicio')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.titulo"
                    :label="$t('$vuetify.titulo')"
                    :items="especializacioItems"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].titulo.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].titulo, 'titulo')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if=" edu.titulo && edu.titulo.value === 2320"
              >
                <v-sheet class="ma-2">
                  <InputTextComponent
                    v-model.trim="edu.otroTitulo"
                    :label="$t('$vuetify.otroTitulo')"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].otroTitulo.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].otroTitulo, 'otroTitulo')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.entidadEducativa"
                    :label="$t('$vuetify.entidadEducativa')"
                    :items="edu.entidadOptions"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].entidadEducativa.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].entidadEducativa, 'entidadEducativa')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
                v-if=" edu.entidadEducativa && edu.entidadEducativa.value === 999"
              >
                <v-sheet class="ma-2">
                  <InputTextComponent
                    v-model.trim="edu.OtraEntidadEducativa"
                    :label="$t('$vuetify.OtraEntidadEducativa')"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].OtraEntidadEducativa.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].OtraEntidadEducativa, 'OtraEntidadEducativa')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.pais"
                    :label="$t('$vuetify.pais')"
                    :items="paisItems"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].pais.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].pais, 'pais')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.estatus"
                    :label="$t('$vuetify.estatus')"
                    :items="estadoFormacionItems"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].estatus.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].estatus, 'estatus')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.mesFin"
                    :label="$t('$vuetify.mesFin')"
                    :items="mesesItems"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].mesFin.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].mesFin, 'mesFin')"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="edu.fechaFin"
                    :label="$t('$vuetify.fechaFin')"
                    :items="rangoFechaOptions.filter(
                      op => edu.fechaInicio && op >= edu.fechaInicio)"
                    required="required"
                    :error="v$.educacion.$each.$response
                      .$errors[index].fechaFin.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.educacion.$each.$response
                      .$errors[index].fechaFin, 'fechaFin')"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="text-center"
          >
            <v-tooltip
              :text="$t('$vuetify.agregar')"
              location="top"
            >
              <template v-slot:activator="{ props }">
                <v-btn-primary @click="addEducacion()" rounded="xl" v-bind="props" density="compact" icon="fa fa-plus" />
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-alert
          variant="outlined"
          :title="`${$t('$vuetify.idioma', 2)}:`"
          border="start"
          color="grey-darken-1"
        />
      </v-row>
      <v-sheet
        class="my-2"
        v-for="(idm, index) in state.idiomas"
        :key="index"
        border>
        <v-row
          no-gutters
          :class="{ 'mb-10': mdAndDown }"
          justify="center"
          align="center"
        >
          <v-col class="text-center">
            <v-tooltip
              :text="$t('$vuetify.eliminar')"
              location="top"
            >
              <template v-slot:activator="{ props }">
                <v-btn @click="delIdioma(index)" v-bind="props" color="red-darken-2" density="compact" icon="fa fa-minus" />
              </template>
            </v-tooltip>
          </v-col>
          <v-col
            cols="12"
            md="11"
          >

            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                md="4"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="idm.idioma"
                    :label="$t('$vuetify.idioma')"
                    :items="idiomaItems"
                  />
                </v-sheet>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-sheet class="ma-2">
                  <AutocompleteComponent
                    v-model="idm.nivel"
                    :label="$t('$vuetify.nivel')"
                    :items="nivelItems"
                    :error="v$.idiomas.$each.$response
                      .$errors[index].nivel.length > 0"
                    :error-messages="useErrorMessagesDyn(v$.idiomas.$each.$response
                      .$errors[index].nivel, 'nivel')"
                  />
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="text-center"
          >
            <v-tooltip
              :text="$t('$vuetify.agregar')"
              location="top"
            >
              <template v-slot:activator="{ props }">
                <v-btn-primary @click="addIdioma()" rounded="xl" v-bind="props" density="compact" icon="fa fa-plus" />
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </v-form>
</template>

<style scoped>

</style>
