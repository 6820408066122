<script setup lang="ts">

const model = defineModel();

defineProps([
  'label',
  'color',
]);

</script>
<template>
  <v-switch
    v-model="model"
    :label="label"
    hide-details
  />
</template>

<style lang="scss" scoped>

</style>
