/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';
import { VBtn } from 'vuetify/components/VBtn';
import { md3 } from 'vuetify/blueprints';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n';
import { createI18n, useI18n } from 'vue-i18n';
import { aliases, fa } from 'vuetify/iconsets/fa-svg';

// import { en, es } from 'vuetify/locale';
import es from '@/locale/es';
import en from '@/locale/en';

// Date i18n
import DateFnsAdapter from '@date-io/date-fns';
import enUS from 'date-fns/locale/en-US';
import esSP from 'date-fns/locale/es';

// I18n

const appName = import.meta.env.VITE_APP_NAME;

const btnColorsSettings = appName === 'GDL' ? {
  VBtnPrimary: {
    color: '#007651',
    rounded: true,
  },
  VSwitch: {
    color: '#007651',
  },
  VDatePicker: {
    color: '#007651',
  },
} : {
  VBtnPrimary: {
    color: '#43b02a',
    rounded: true,
  },
  VSwitch: {
    color: '#43b02a',
  },
  VDatePicker: {
    color: '#43b02a',
  },
};

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: 'es',
  fallbackLocale: 'en',
  messages: { es, en },
});

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
const vuetifyInstance = createVuetify({
  blueprint: md3,
  aliases: {
    VBtnPrimary: VBtn,
  },
  defaults: btnColorsSettings,
  date: {
    adapter: DateFnsAdapter,
    locale: {
      es: esSP,
      en: enUS,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
});

export {
  vuetifyInstance,
  i18n,
};
