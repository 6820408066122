import { useI18n } from 'vue-i18n';

const useErrorMessages = (v$: any, errors: any) => {
  const { t } = useI18n();
  // Función para obtener mensajes de error específicos para un campo
  const getErrorMessages = (fieldName: string) => {
    const propStore: any = fieldName.split('.');

    const fieldErrors = errors.value[propStore.pop()];
    if (!fieldErrors) return [];

    const errorMessages = [];
    if (fieldErrors.includes('obligatorio') || fieldErrors.includes('required')) {
      errorMessages.push(t('$vuetify.validations.required', { field: t(`$vuetify.${fieldName}`).toLowerCase() }));
    }

    if (fieldErrors.includes('mínima') || fieldErrors.includes('min')) {
    // console.log(v$.value);
      const [props1, props2] = fieldName.split('.');
      const lengthDyn = (props1 && props2)
        ? v$.value?.[props1]?.[props2]?.minLength.$params.min
        : v$.value?.[props1]?.minLength.$params.min;
      errorMessages.push(t(
        '$vuetify.validations.minLength',
        { field: t(`$vuetify.${fieldName}`).toLowerCase(), length: lengthDyn },
      ));
    }

    if (fieldErrors.includes('máxima') || fieldErrors.includes('max')) {
    // console.log(v$.value);
      const [props1, props2] = fieldName.split('.');
      const lengthDyn = (typeof props1 !== 'undefined' && typeof props2 !== 'undefined')
        ? v$.value?.[props1]?.[props2]?.maxLength.$params.max
        : v$.value?.[props1]?.maxLength.$params.max;
      errorMessages.push(t(
        '$vuetify.validations.maxLength',
        { field: t(`$vuetify.${fieldName}`).toLowerCase(), length: lengthDyn },
      ));
    }

    if (fieldErrors.includes('válido') || fieldErrors.includes('valid')) {
      errorMessages.push(t('$vuetify.validations.email', { field: t(`$vuetify.${fieldName}`).toLowerCase() }));
    }

    if (fieldErrors.includes('numeros') || fieldErrors.includes('number')) {
      errorMessages.push(t('$vuetify.validations.numeric', { field: t(`$vuetify.${fieldName}`).toLowerCase() }));
    }
    return errorMessages;
  };

  return { getErrorMessages };
};

export default useErrorMessages;
