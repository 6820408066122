<script setup lang="ts">
const model = defineModel<File[]>();

defineProps([
  'label',
  'items',
  'required',
  'accept',
  'rules',
  'hint',
]);

</script>
<template>
  <v-file-input
    v-model="model"
    clearable
    show-size
    :label="label"
    variant="outlined"
    prepend-icon="fa fa-upload"
    persistent-hint
    :hint="hint"
    hide-details="auto"
    density="compact"
    :rules="rules"
    :required="required"
    :accept="accept" />
</template>

<style scoped>

</style>
