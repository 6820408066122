/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import type { App } from 'vue';

import { vMaska } from 'maska';
import * as Sentry from '@sentry/vue';
import { vuetifyInstance, i18n } from './vuetify';
import pinia from '../store';
import router from '../router';

// VueSweetalert2
import 'sweetalert2/dist/sweetalert2.min.css';

export default function registerPlugins(app: App) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_NODE_ENV,
    dsn: (import.meta.env.VITE_NODE_ENV === 'development')
      ? '' : 'https://f32816a3c0f348bfb368db5b9d165c4a@o82137.ingest.us.sentry.io/4505075127156736',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    tracesSampleRate: 1.0,
  });

  app
    .use(pinia)
    .use(router)
    .use(i18n)
    .use(vuetifyInstance)
    .directive('maska', vMaska);
}
