/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import registerPlugins from '@/plugins';

// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

// Components
import {
  createApp,
} from 'vue';
import App from './App.vue';
import UppercaseDirective from './helpers/UppercaseDirective';

// Composables

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon); // Register component globally
library.add(fas); // Include needed solid icons
library.add(far); // Include needed regular icons

registerPlugins(app);

app.directive('uppercase', UppercaseDirective);
app.mount('#app');
