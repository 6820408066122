import { useI18n } from 'vue-i18n';

// Función para obtener mensajes de error específicos para campos dinámicos
const useErrorMessagesDyn = (input: any[], modelName: string) => {
  const { t } = useI18n();
  if (input.length === 0) return [];

  const errorMessages: any[] = [];

  input.forEach((vInput) => {
    const field = t(`$vuetify.${modelName}`).toLowerCase();

    switch (vInput.$validator) {
      case 'requiredIf':
      case 'required':
        errorMessages.push(t('$vuetify.validations.required', { field }));
        break;
      case 'numeric':
        errorMessages.push(t('$vuetify.validations.numeric', { field }));
        break;
      case 'minLength':
        if (vInput.$params?.min) {
          errorMessages.push(t('$vuetify.validations.minLength', { field, length: vInput.$params.min }));
        }
        break;
      case '$each':
        errorMessages.push(t('$vuetify.validations.unknown', { field }));
        break;
      default:
        // console.warn(`Validator not recognized: ${vInput.$validator}`);
        errorMessages.push(t('$vuetify.validations.unknown', { field }));
        break;
    }
  });

  return errorMessages;
};

export default useErrorMessagesDyn;
