import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';

const setNullState = (properties: string[]): void => {
  const {
    initialState: state,
  } = storeToRefs(useAppStore());

  if (properties.length > 0) {
    properties.forEach((p) => {
      // Verifica si 'p' es una clave válida en 'state.value'
      if (p in state.value) {
        // Usamos type assertion con 'as'
        (state.value[p as keyof typeof state.value] as any) = null;
      }
    });
  }
};

export default setNullState;
