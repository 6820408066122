const getAxios = async () => {
  const axios = await import('axios');
  return axios.default.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default getAxios;
