import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const useItemsGlobal = () => {
  const { t } = useI18n();

  const tratamientoItems = computed(() => ([
    { text: t('$vuetify.senor'), value: 'Sr.' },
    { text: t('$vuetify.senora'), value: 'Sra.' },
    { text: t('$vuetify.senorita'), value: 'Srta.' },
  ]));

  const estadoCivilItems = computed(() => ([
    { text: t('$vuetify.casado'), value: 'cas.' },
    { text: t('$vuetify.divorciado'), value: 'div.' },
    { text: t('$vuetify.soltero'), value: 'sol.' },
    { text: t('$vuetify.unionlibre'), value: 'Ulibre' },
    { text: t('$vuetify.viudo'), value: 'viu.' },
  ]));

  const generoItems = computed(() => ([
    { text: t('$vuetify.masculino'), value: 1 },
    { text: t('$vuetify.femenino'), value: 2 },
    { text: t('$vuetify.desconocido'), value: '' },
  ]));

  const categoriaItems = computed(() => ([
    { text: `${t('$vuetify.categoriaLicencia')} 1`, value: 1 },
    { text: `${t('$vuetify.categoriaLicencia')} 2`, value: 2 },
    { text: `${t('$vuetify.categoriaLicencia')} 3`, value: 3 },
    { text: `${t('$vuetify.categoriaLicencia')} 4`, value: 4 },
    { text: `${t('$vuetify.categoriaLicencia')} 5`, value: 5 },
  ]));

  const llegasteItems = computed(() => ([
    { text: t('$vuetify.WEBSITE'), value: 'Página WEB' },
    { text: t('$vuetify.FACEBOOK'), value: 'Facebook' },
    { text: t('$vuetify.INSTAGRAM'), value: 'Instagram' },
    { text: t('$vuetify.LINKEDIN'), value: 'LinkedIn' },
    { text: t('$vuetify.REFERRALS'), value: 'Referidos' },
    { text: t('$vuetify.OTHERS'), value: 'Otros' },
  ]));

  const mesesItems = computed(() => ([
    { text: t('$vuetify.ENERO'), value: '01' },
    { text: t('$vuetify.FEBRERO'), value: '02' },
    { text: t('$vuetify.MARZO'), value: '03' },
    { text: t('$vuetify.ABRIL'), value: '04' },
    { text: t('$vuetify.MAYO'), value: '05' },
    { text: t('$vuetify.JUNIO'), value: '06' },
    { text: t('$vuetify.JULIO'), value: '07' },
    { text: t('$vuetify.AGOSTO'), value: '08' },
    { text: t('$vuetify.SEPTIEMBRE'), value: '09' },
    { text: t('$vuetify.OCTUBRE'), value: '10' },
    { text: t('$vuetify.NOVIEMBRE'), value: '11' },
    { text: t('$vuetify.DICIEMBRE'), value: '12' },
  ]));

  const razonSalidaItems = computed(() => ([
    { text: t('$vuetify.RENUNCIA'), value: 9 },
    { text: t('$vuetify.DESAHUCIO'), value: 1 },
    { text: t('$vuetify.REDUCCION'), value: 7 },
  ]));

  const rangoSalarioItems = computed(() => ([
    { text: '< 15,000', value: 1 },
    { text: '15,000-20,000', value: 2 },
    { text: '20,000-30,000', value: 3 },
    { text: '30,000-50,000', value: 4 },
    { text: '50,000-80,000', value: 5 },
    { text: '80,000-100,000', value: 6 },
    { text: '> 100,000', value: 7 },
  ]));

  const tipoEducacionItems = computed(() => ([
    { text: t('$vuetify.primaria'), value: '01' },
    { text: t('$vuetify.secundaria'), value: '02' },
    { text: t('$vuetify.tecnico'), value: '03' },
    { text: t('$vuetify.universitaria'), value: '04' },
    { text: t('$vuetify.postGrado'), value: '05' },
    { text: t('$vuetify.maestria'), value: '06' },
    { text: t('$vuetify.doctorado'), value: '07' },
    { text: t('$vuetify.diplomado'), value: '08' },
    { text: t('$vuetify.curso'), value: '09' },
    { text: t('$vuetify.otro'), value: 'Z9' },
  ]));

  const nivelItems = computed(() => ([
    { text: t('$vuetify.INICIAL'), value: 'Inicial' },
    { text: t('$vuetify.INTERMEDIO'), value: 'Intermedio' },
    { text: t('$vuetify.AVANZADO'), value: 'Avanzado' },
    { text: t('$vuetify.NATIVO'), value: 'Nativo' },
  ]));

  const estatusResidenciaItems = computed(() => ([
    { text: t('$vuetify.vigente'), value: 1 },
    { text: t('$vuetify.proceso'), value: 2 },
    { text: t('$vuetify.vencida'), value: 3 },
  ]));

  return {
    tratamientoItems,
    estadoCivilItems,
    generoItems,
    categoriaItems,
    llegasteItems,
    mesesItems,
    razonSalidaItems,
    rangoSalarioItems,
    tipoEducacionItems,
    nivelItems,
    estatusResidenciaItems,
  };
};

export default useItemsGlobal;
