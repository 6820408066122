<script setup lang="ts">
import {
  computed, onMounted, ref, watch,
} from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';
import { useVuelidate } from '@vuelidate/core';
import * as validators from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

// Composable
import useValidationErrors from '@/composables/useValidationErrors';
import useItems from '@/composables/useItems';
import setNullState from '@/helpers/SetNullStore';
import useErrorMessages from '@/composables/useErrorMessages';
import useItemsGlobal from '@/composables/useItemsGlobal';

const appName = import.meta.env.VITE_APP_NAME;

const {
  tratamientoItems,
  estadoCivilItems,
  generoItems,
  categoriaItems,
  llegasteItems,
  estatusResidenciaItems,
} = useItemsGlobal();

const { t } = useI18n();

const {
  loading, initialState: state,
} = storeToRefs(useAppStore());

const { getProvinciaPais } = useAppStore();

interface Item {
  value: string;
  text: string;
}

const lugarNacimientoItems = ref<Item[]>([]);
const provinciaItems = ref<Item[]>([]);

watch(
  () => state.value.paisNacimiento,
  async (newValue: { text: string, value: string } | null) => {
    lugarNacimientoItems.value = [];
    state.value.lugarNacimiento = null;
    if (newValue && 'value' in newValue) {
      lugarNacimientoItems.value = await getProvinciaPais(newValue.value);
    }
  },
);

watch(
  () => state.value.direccion.pais,
  async (newValue: { text: string, value: string } | null) => {
    provinciaItems.value = [];
    state.value.direccion.provincia = null;
    if (newValue && 'value' in newValue) {
      provinciaItems.value = await getProvinciaPais(newValue.value);
    }
  },
);

/*
Parametros para generar los items dinamicos de select y autocomplete
del servicio /formadmission?lang=es
1-Param Nombre del objecto en mayusculas
2-param nombre del store del input
3-Propiedad de la data a mostrar en el text del item
4-Propiedad de la data para el value del item
*/
const nacionalidadItems = useItems('PAISES', 'nacionalidad', 'NATIO', 'LAND1', true);
const paisItems = useItems('PAISES', 'paisNacimiento', 'LANDX', 'LAND1');
const paisDicItems = useItems('PAISES', 'direccion.pais', 'LANDX', 'LAND1');

const cerrarMenu = () => {
  if (state.value.fechaVigenciaLicencia) state.value.menuLicencia = false;
  state.value.fechaMode = 'year';
  state.value.fechaNacimiento_month = 0;
  if (state.value.fechaVigenciaLicencia) {
    const FechaFormat = state.value.fechaVigenciaLicencia.toISOString().slice(0, 10);
    const [year, month, day] = FechaFormat.split('-');
    state.value.fechaVigenciaLicencia_format = `${day}-${month}-${year}`;
  }
};

const cerrarMenuVenc = () => {
  if (state.value.fechaVigencia) state.value.menuVigencia = false;
  state.value.fechaMode = 'year';
  state.value.fechaVigecia_month = -200;
  if (state.value.fechaVigencia) {
    const FechaNacFormat = state.value.fechaVigencia.toISOString().slice(0, 10);
    const [year, month, day] = FechaNacFormat.split('-');
    state.value.fechaVigencia_format = `${day}-${month}-${year}`;
  }
};

const fechaMayor = (new Date(new Date().setFullYear(new Date().getFullYear() + 4)));
const fechaMin = (new Date(new Date().setFullYear(new Date().getFullYear())));

// Rango para fecha de vigencia
const fechaMayorV = (new Date(new Date().setFullYear(new Date().getFullYear() + 10)));
const fechaMinV = (new Date(new Date().setFullYear(new Date().getFullYear() - 15)));

const { createI18nMessage } = validators;
const messagePath = ({ $validator, $params }: any) => {
  const fieldName = $params?.$field || $validator;
  return `$vuetify.validations.${fieldName}`;
};

const withI18nMessage = createI18nMessage({ t, messagePath });
const required = withI18nMessage(validators.required);
const requiredIf = (condition: () => boolean) => withI18nMessage(validators.requiredIf(condition));
const minLength = (length: number) => withI18nMessage(validators.minLength(length));
const maxLength = (length: number) => withI18nMessage(validators.maxLength(length));
const numeric = withI18nMessage(validators.numeric);

const rules = computed(() => ({
  tratamiento: {
    required,
  },
  celular: {
    required,
    numeric,
    minLength: minLength(9),
    maxLength: maxLength(16),
  },
  estadoCivil: {
    required,
  },
  nacionalidad: {
    required,
  },
  genero: {
    required,
  },
  paisNacimiento: {
    required,
  },
  lugarNacimiento: {
    required,
  },
  otroLugarNacimiento: {
    requiredIf: requiredIf(() => {
      const { lugarNacimiento } = state.value;
      if (lugarNacimiento && 'value' in lugarNacimiento) {
        return lugarNacimiento.value === 'ZZ';
      }
      return false;
    }),
    minLength: minLength(5),
  },
  categoriaLicencia: {
    requiredIf: requiredIf(() => state.value.licencia),
  },
  fechaVigenciaLicencia: {
    requiredIf: requiredIf(() => state.value.licencia),
  },
  comoLlegaste: {
    required,
  },
  comoLlegasteOtro: {
    requiredIf: requiredIf(() => {
      const { comoLlegaste } = state.value;
      if (comoLlegaste && 'value' in comoLlegaste) {
        return comoLlegaste.value === 'Otros';
      }
      return false;
    }),
    minLength: minLength(5),
  },
  familiarNombre: {
    requiredIf: requiredIf(() => state.value.tieneFamiliar),
    minLength: minLength(5),
  },
  referidoNombre: {
    requiredIf: requiredIf(() => state.value.referido),
    minLength: minLength(5),
  },
  direccion: {
    calle: {
      required,
      minLength: minLength(5),
    },
    numero: {
      maxLength: maxLength(5),
    },
    sector: {
      required,
      minLength: minLength(3),
    },
    ciudad: {
      minLength: minLength(3),
    },
    pais: {
      required,
    },
    provincia: {
      required,
    },
    tel_residencial: {
      numeric,
      minLength: minLength(9),
      maxLength: maxLength(16),
    },
    otra_provincia: {
      requiredIf: requiredIf(() => {
        const { provincia } = state.value.direccion;
        if (provincia && 'value' in provincia) {
          return provincia.value === 'ZZ';
        }
        return false;
      }),
      minLength: minLength(5),
    },
  },
}));

onMounted(async () => {
  // Validar si el campo pais nacimiento tiene valor
  // para setear el valor del lugar de nacimiento si no tiene
  if (state.value.paisNacimiento && lugarNacimientoItems.value.length === 0) {
    const { value } = state.value.paisNacimiento;
    lugarNacimientoItems.value = await getProvinciaPais(value);
  }

  // Validar si el campo pais en direccion tiene valor
  // para setear el valor de la provincia en la direccion si no tiene
  if (state.value.direccion.pais && provinciaItems.value.length === 0) {
    const { value } = state.value.direccion.pais;
    provinciaItems.value = await getProvinciaPais(value);
  }
});

const v$ = useVuelidate(rules, state);

const errors = computed(() => useValidationErrors<any>(v$.value.$errors));

const { getErrorMessages } = useErrorMessages(v$, errors);

async function submitForm() {
  return v$.value.$validate();
}

defineExpose({
  submitForm,
});
</script>
<template>
  <v-form @submit.prevent="" ref="formFile" :disabled="loading">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-alert
          variant="outlined"
          :title="`${$t('$vuetify.datosPersonales')}:`"
          border="start"
          color="grey-darken-1"
        />
      </v-row>
      <v-row no-gutters v-if="state.tipoID && state.tipoID?.value === 'Z2'">
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.estatusResidencia"
              :label="$t('$vuetify.estatusResidencia')"
              :items="estatusResidenciaItems"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <v-menu v-model="state.menuVigencia" :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-sheet class="ma-2">
                  <InputTextComponent
                    v-model="state.fechaVigencia_format"
                    @click="setNullState(['fechaVigencia', 'fechaVigencia_format'])"
                    v-bind="props"
                    :label="$t('$vuetify.fechaVigencia')"
                    readonly
                    required="required"
                    prepend-inner-icon="fas fa-calendar"
                    color="secondary"
                  />
                </v-sheet>
              </template>

              <v-date-picker
                v-model:model-value="state.fechaVigencia"
                v-model:month="state.fechaVigecia_month"
                @update:modelValue="cerrarMenuVenc()"
                @update:year="state.fechaMode = 'months'"
                :max="fechaMayorV"
                :min="fechaMinV"
                :view-mode="state.fechaMode"
              />
            </v-menu>
          </div>

        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.tratamiento"
              :label="$t('$vuetify.tratamiento')"
              :items="tratamientoItems"
              :error="v$.tratamiento.$error"
              :errorMessages="getErrorMessages('tratamiento')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.direccion.tel_residencial"
              :label="$t('$vuetify.direccion.tel_residencial')"
              required="required"
              type="tel"
              :error="v$.direccion.tel_residencial.$error"
              :errorMessages="getErrorMessages('direccion.tel_residencial')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.celular"
              :label="$t('$vuetify.celular')"
              required="required"
              type="tel"
              :error="v$.celular.$error"
              :errorMessages="getErrorMessages('celular')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.estadoCivil"
              :label="$t('$vuetify.estadoCivil')"
              :items="estadoCivilItems"
              :error="v$.estadoCivil.$error"
              :errorMessages="getErrorMessages('estadoCivil')"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.cantidadHijos"
              :label="$t('$vuetify.cantidadHijos')"
              :items="Array.from({ length: 21 }, (value, index) => index)"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.nacionalidad"
              :label="$t('$vuetify.nacionalidad')"
              :items="nacionalidadItems"
              :error="v$.nacionalidad.$error"
              :errorMessages="getErrorMessages('nacionalidad')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.genero"
              :label="$t('$vuetify.genero')"
              :items="generoItems"
              :error="v$.genero.$error"
              :errorMessages="getErrorMessages('genero')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.paisNacimiento"
              :label="$t('$vuetify.paisNacimiento')"
              :items="paisItems"
              :error="v$.paisNacimiento.$error"
              :errorMessages="getErrorMessages('paisNacimiento')"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.lugarNacimiento"
              :label="$t('$vuetify.lugarNacimiento')"
              :items="lugarNacimientoItems"
              :error="v$.lugarNacimiento.$error"
              :disabled="lugarNacimientoItems && lugarNacimientoItems.length === 0"
              :errorMessages="getErrorMessages('lugarNacimiento')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.lugarNacimiento && state.lugarNacimiento.value === 'ZZ'"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.otroLugarNacimiento"
              :label="$t('$vuetify.otroLugarNacimiento')"
              required="required"
              :error="v$.otroLugarNacimiento.$error"
              :errorMessages="getErrorMessages('otroLugarNacimiento')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.comoLlegaste"
              :label="$t('$vuetify.comoLlegaste')"
              :items="llegasteItems"
              :error="v$.comoLlegaste.$error"
              :errorMessages="getErrorMessages('comoLlegaste')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.comoLlegaste && state.comoLlegaste.value === 'Otros'"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.comoLlegasteOtro"
              :label="$t('$vuetify.comoLlegasteOtro')"
              required="required"
              :error="v$.comoLlegasteOtro.$error"
              :errorMessages="getErrorMessages('comoLlegasteOtro')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet>
            <SwitchComponent
              v-model="state.licencia"
              :label="`${$t('$vuetify.licencia')}: ${state.licencia ? $t('$vuetify.si') : 'No'}`"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.licencia"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.categoriaLicencia"
              :label="$t('$vuetify.categoriaLicencia')"
              :items="categoriaItems"
              :error="v$.categoriaLicencia.$error"
              :errorMessages="getErrorMessages('categoriaLicencia')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.licencia"
        >
          <div>
            <v-menu v-model="state.menuLicencia" :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-sheet class="ma-2">
                  <InputTextComponent
                    v-model="state.fechaVigenciaLicencia_format"
                    @click="setNullState(['fechaVigenciaLicencia', 'fechaVigenciaLicencia_format'])"
                    ck="state.fechaVigenciaLicencia = null; state.fechaVigenciaLicencia_format = null"
                    v-bind="props"
                    :label="$t('$vuetify.fechaVigenciaLicencia')"
                    readonly
                    required="required"
                    prepend-inner-icon="fas fa-calendar"
                    color="secondary"
                    :error="v$.fechaVigenciaLicencia.$error"
                    :errorMessages="getErrorMessages('fechaVigenciaLicencia')"
                  />
                </v-sheet>
              </template>

              <v-date-picker
                v-model:model-value="state.fechaVigenciaLicencia"
                v-model:month="state.fechaNacimiento_month"
                @update:modelValue="cerrarMenu()"
                @update:year="state.fechaMode = 'months'"
                :max="fechaMayor"
                :min="fechaMin"
                :view-mode="state.fechaMode"
              />
            </v-menu>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet>
            <SwitchComponent
              v-model="state.tieneFamiliar"
              :label="`${$t('$vuetify.tieneFamiliar')} ${appName === 'GDL' ? 'Grupo Diario Libre' : 'Grupo Puntacana'}? :
                                  ${state.tieneFamiliar ? $t('$vuetify.si') : 'No'}`"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.tieneFamiliar"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.familiarNombre"
              :label="$t('$vuetify.familiarNombre')"
              required="required"
              :error="v$.familiarNombre.$error"
              :errorMessages="getErrorMessages('familiarNombre')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet>
            <SwitchComponent
              v-model="state.referido"
              :label="`${$t('$vuetify.referido')}:
                                  ${state.referido ? $t('$vuetify.si') : 'No'}`"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.referido"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.referidoNombre"
              :label="$t('$vuetify.referidoNombre')"
              required="required"
              :error="v$.referidoNombre.$error"
              :errorMessages="getErrorMessages('referidoNombre')"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet>
            <SwitchComponent
              v-model="state.rotativo"
              :label="`${$t('$vuetify.rotativo')}:
                                  ${state.rotativo ? $t('$vuetify.si') : 'No'}`"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet>
            <SwitchComponent
              v-model="state.movilidad"
              :label="`${$t('$vuetify.movilidad')} :
                                  ${state.movilidad ? $t('$vuetify.si') : 'No'}`"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-alert
          variant="outlined"
          :title="`${$t('$vuetify.direccionTitle')}:`"
          border="start"
          color="grey-darken-1"
        />
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.direccion.calle"
              :label="$t('$vuetify.direccion.calle')"
              required="required"
              :error="v$.direccion.calle.$error"
              :errorMessages="getErrorMessages('direccion.calle')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.direccion.numero"
              :label="$t('$vuetify.direccion.numero')"
              required="required"
              :error="v$.direccion.numero.$error"
              :errorMessages="getErrorMessages('direccion.numero')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.direccion.sector"
              :label="$t('$vuetify.direccion.sector')"
              required="required"
              :error="v$.direccion.sector.$error"
              :errorMessages="getErrorMessages('direccion.sector')"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.direccion.ciudad"
              :label="$t('$vuetify.direccion.ciudad')"
              required="required"
              :error="v$.direccion.ciudad.$error"
              :errorMessages="getErrorMessages('direccion.ciudad')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.direccion.pais"
              :label="$t('$vuetify.direccion.pais')"
              :items="paisDicItems"
              :error="v$.direccion.pais.$error"
              :errorMessages="getErrorMessages('direccion.pais')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.direccion.provincia"
              :label="$t('$vuetify.direccion.provincia')"
              :items="provinciaItems"
              :error="v$.direccion.provincia.$error"
              :disabled="provinciaItems && provinciaItems.length === 0"
              :errorMessages="getErrorMessages('direccion.provincia')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          v-if="state.direccion.provincia && state.direccion.provincia.value === 'ZZ'"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.direccion.otra_provincia"
              :label="$t('$vuetify.direccion.otra_provincia')"
              required="required"
              :error="v$.direccion.otra_provincia.$error"
              :errorMessages="getErrorMessages('direccion.otra_provincia')"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<style scoped>

</style>
