import { es } from 'vuetify/locale';

export default {
  $vuetify: {
    ...es,
    'SOLICITUD DE CANDIDATO': 'SOLICITUD DE CANDIDATO',
    paso1: 'Información del Candidato - Paso 1',
    paso2: 'Datos Educativos - Paso 2',
    paso3: 'Experiencia Laboral / CV - Paso 3',
    tipoID: 'Tipo de ID',
    Cedula: 'Cédula',
    Pasaporte: 'Pasaporte',
    identificacion: 'Número de identificación',
    nombre: 'Nombre(s)',
    primerApellido: 'Primer apellido',
    segundoApellido: 'Segundo apellido',
    estatusResidencia: 'Estatus de residencia',
    email: 'Correo electrónico',
    tieneCorreo: "¿Tiene usted @.lower:{'$vuetify.email'}?",
    si: 'Sí',
    fechaNacimiento: 'Fecha de nacimiento',
    confirmar: 'Confirmar',
    tratamiento: 'Tratamiento',
    celular: '@:$vuetify.telefono celular',
    senor: 'SEÑOR',
    senora: 'SEÑORA',
    senorita: 'SEÑORITA',
    estadoCivil: 'Estado civil',
    casado: 'CASADO/A',
    divorciado: 'DIVORCIADO/A',
    soltero: 'SOLTERO/A',
    unionlibre: 'UNIÓN LIBRE',
    viudo: 'VIUDO/A',
    cantidadHijos: 'Cantidad de hijos',
    nacionalidad: 'Nacionalidad',
    genero: 'Género',
    masculino: 'MASCULINO',
    femenino: 'FEMENINO',
    desconocido: 'DESCONOCIDO',
    paisNacimiento: 'País de nacimiento',
    lugarNacimiento: 'Lugar de nacimiento',
    otroLugarNacimiento: '@:$vuetify.otro @.lower:$vuetify.lugarNacimiento',
    categoriaLicencia: 'Categoría',
    fechaVigenciaLicencia: 'Fecha vigencia licencia',
    tieneFamiliar: '¿Tiene algún familiar que trabaja en',
    comoLlegaste: '¿Cómo llegaste a nosotros?',
    comoLlegasteOtro: 'Especificar',
    licencia: 'Licencia de conducir',
    familiarNombre: 'Nombre del familiar',
    referido: '¿Está usted referido por alguien?',
    referidoNombre: 'Escriba el nombre',
    rotativo: '¿Tiene usted disponibilidad para trabajar horario rotativo?',
    movilidad: 'Movilidad - ¿Tiene usted disponibilidad de moverse a otras ciudades?',
    datosPersonales: 'Datos personales',
    direccionTitle: 'Dirección',
    direccion: {
      calle: '@:$vuetify.direccionTitle (Calle/Residencial/Edificio)',
      numero: 'Número',
      sector: 'Sector',
      ciudad: 'Ciudad',
      pais: '@:$vuetify.pais',
      provincia: '@:$vuetify.provincia',
      otra_provincia: '@:$vuetify.otraProvincia',
      tel_residencial: '@:$vuetify.telefono residencial',
    },
    pais: 'País',
    tipoEducacion: 'Tipo educación',
    otroTipoEducacion: '@:$vuetify.otro tipo educación',
    mesInicio: 'Mes inicio',
    fechaInicio: 'Año inicio',
    titulo: 'Especialización o título',
    otroTitulo: 'Otra especialización o título',
    entidadEducativa: 'Entidad educativa',
    OtraEntidadEducativa: 'Nombre entidad educativa',
    estatus: 'Estatus',
    mesFin: 'Mes fin',
    fechaFin: 'Año fin',
    idioma: 'Idioma | Idiomas',
    nivel: 'Nivel',
    agregar: 'Agregar',
    eliminar: 'Eliminar',
    expLaboral: 'Tiene experiencia laboral?',
    empresa: 'Nombre de la empresa',
    telefono: 'Teléfono',
    provincia: 'Provincia/Estado',
    otraProvincia: 'Otra provincia/Estado',
    posicion: 'Posición desempeñada',
    otraPosicion: 'Otra posición desempeñada',
    sectorEmpresarial: 'Sector empresarial',
    otroSectorEmpresarial: '@:$vuetify.otro sector empresarial',
    activoEmpresa: '¿Usted está activo en esta empresa?',
    razonSalida: 'Razón de la salida',
    mesSalida: 'Mes salida',
    fechaSalida: 'Año salida',
    rangoSalario: 'Rango de salario',
    supervisor: 'Supervisor inmediato',
    trabajado: 'Usted ha trabajado anteriormente con nosotros?',
    interes: 'Interés',
    intereshint: 'Puede seleccionar una o varias áreas de interés.',
    vacanteshint: 'Puede seleccionar una o varias vacantes.',
    archivoCV: 'Cargar CV',
    vacantes: 'Vacantes disponibles: (Opcional)',
    validations: {
      required: 'El campo {field} es obligatorio.',
      numeric: 'El campo {field} debe ser solo numeros.',
      requiredIf: 'El campo {field} es obligatorio.',
      minLength: 'El campo {field}, debe tener una longitud mínima de {length} carácteres. {min}',
      maxLength: 'El campo {field}, debe tener una longitud máxima de {length} carácteres. {max}',
      email: 'El campo {field} debe ser válido.',
      filetype: 'Error en el archivo debe ser .pdf',
      fileweight: 'El archivo debe ser inferior a 2 MB.',
    },
    archivohint: 'Solo es permitido archivo .PDF',
    archivoActulizar: 'Actualizar documento',
    open: 'Open',
    vigente: 'Vigente',
    proceso: 'En Proceso',
    vencida: 'Vencida',
    fechaVigencia: 'Fecha vigencia de residencia',
    ENERO: 'ENERO',
    FEBRERO: 'FEBRERO',
    MARZO: 'MARZO',
    ABRIL: 'ABRIL',
    MAYO: 'MAYO',
    JUNIO: 'JUNIO',
    JULIO: 'JULIO',
    AGOSTO: 'AGOSTO',
    SEPTIEMBRE: 'SEPTIEMBRE',
    OCTUBRE: 'OCTUBRE',
    NOVIEMBRE: 'NOVIEMBRE',
    DICIEMBRE: 'DICIEMBRE',
    WEBSITE: 'PÁGINA WEB',
    FACEBOOK: 'FACEBOOK',
    INSTAGRAM: 'INSTAGRAM',
    LINKEDIN: 'LINKEDIN',
    REFERRALS: 'REFERIDOS',
    OTHERS: 'OTROS',
    INICIAL: 'INICIAL',
    INTERMEDIO: 'INTERMEDIO',
    AVANZADO: 'AVANZADO',
    NATIVO: 'NATIVO',
    RENUNCIA: 'RENUNCIA',
    DESAHUCIO: 'DESAHUCIO',
    REDUCCION: 'REDUCCIÓN DE PERSONAL',
    atras: 'Atrás',
    cancelar: 'Cancelar',
    continuar: 'Continuar',
    enviar: 'Enviar',
    primaria: 'Primaria',
    secundaria: 'Secundaria',
    tecnico: 'Técnico Superior',
    universitaria: 'Universitaria',
    postGrado: 'Post Grado',
    maestria: 'Maestría',
    doctorado: 'Doctorado',
    diplomado: 'Diplomado/Certificación',
    curso: 'Curso',
    otro: 'Otro',
    datePicker: {
      title: 'Seleccione la fecha',
      header: 'Introduzca la fecha',
    },
  },
};
