import { en } from 'vuetify/locale';

export default {
  $vuetify: {
    ...en,
    'SOLICITUD DE CANDIDATO': 'APPLICATION FOR EMPLOYMENT',
    paso1: 'Candidate Information - Step 1',
    paso2: 'Educational Data - Step 2',
    paso3: 'Work Experience / CV - Step 3',
    tipoID: 'ID Type',
    Cedula: 'ID Card',
    Pasaporte: 'Passport',
    identificacion: 'Identification Number',
    nombre: 'First Name',
    primerApellido: 'First Surname',
    segundoApellido: 'Second Surname',
    estatusResidencia: 'Residence Status',
    email: 'Email',
    tieneCorreo: 'Do you have an email?',
    si: 'Yes',
    fechaNacimiento: 'Date of Birth',
    confirmar: 'Confirm',
    tratamiento: 'Title',
    celular: '@:$vuetify.telefono mobile',
    senor: 'MR.',
    senora: 'MRS.',
    senorita: 'MISS',
    estadoCivil: 'Marital Status',
    casado: 'MARRIED',
    divorciado: 'DIVORCED',
    soltero: 'SINGLE',
    unionlibre: 'COMMON-LAW',
    viudo: 'WIDOWED',
    cantidadHijos: 'Number of Children',
    nacionalidad: 'Nationality',
    genero: 'Gender',
    masculino: 'MALE',
    femenino: 'FEMALE',
    desconocido: 'UNKNOWN',
    paisNacimiento: 'Country of Birth',
    lugarNacimiento: 'Place of Birth',
    otroLugarNacimiento: 'Another Place of Birth',
    categoriaLicencia: 'Category',
    fechaVigenciaLicencia: 'License Validity Date',
    tieneFamiliar: 'Do you have any family members working at',
    comoLlegaste: 'How did you hear about us?',
    comoLlegasteOtro: 'Specify',
    licencia: 'Driver\'s License',
    familiarNombre: 'Family Member\'s Name',
    referido: 'Are you referred by someone?',
    referidoNombre: 'Enter the name',
    rotativo: 'Are you available to work in rotating shifts?',
    movilidad: 'Mobility - Are you available to move to other cities?',
    datosPersonales: 'Personal Information',
    direccionTitle: 'Address',
    direccion: {
      calle: '@:$vuetify.direccionTitle (Street/Residential/Building)',
      numero: 'Number',
      sector: 'Sector',
      ciudad: 'City',
      pais: '@:$vuetify.pais',
      provincia: '@:$vuetify.provincia',
      otra_provincia: '@:$vuetify.otraProvincia',
      tel_residencial: '@:$vuetify.telefono residencial',
    },
    pais: 'Country',
    tipoEducacion: 'Type of Education',
    otroTipoEducacion: 'Other Type of Education',
    mesInicio: 'Start Month',
    fechaInicio: 'Start Year',
    titulo: 'Specialization or Title',
    otroTitulo: 'Other Specialization or Title',
    entidadEducativa: 'Educational Institution',
    OtraEntidadEducativa: 'Name of Educational Institution',
    estatus: 'Status',
    mesFin: 'End Month',
    fechaFin: 'End Year',
    idioma: 'Language | Languages',
    nivel: 'Level',
    agregar: 'Add',
    eliminar: 'Delete',
    expLaboral: 'Do you have work experience?',
    empresa: 'Company Name',
    telefono: 'Phone',
    provincia: 'Province/State',
    otraProvincia: 'Another Province/State',
    posicion: 'Position Held',
    otraPosicion: 'Another Position Held',
    sectorEmpresarial: 'Business Sector',
    otroSectorEmpresarial: 'Another Business Sector',
    activoEmpresa: 'Are you currently active in this company?',
    razonSalida: 'Reason for Leaving',
    mesSalida: 'Month of separation',
    fechaSalida: 'Year of separation',
    rangoSalario: 'Salary Range',
    supervisor: 'Immediate Supervisor',
    trabajado: 'Have you worked with us before?',
    interes: 'Interest',
    intereshint: 'You can select one or more areas of interest.',
    vacanteshint: 'You can select one or more available positions.',
    archivoCV: 'Upload CV',
    vacantes: 'Available Positions: (Optional)',
    validations: {
      required: 'The {field} field is required.',
      numeric: 'The {field} field must be numeric.',
      requiredIf: 'The {field} field is required.',
      minLength: 'The {field} field must have a minimum length of {length} characters. {min}',
      maxLength: 'The {field} field must have a maximum length of {length} characters. {max}',
      email: 'The {field} field must be a valid email.',
      filetype: 'File error, it must be .pdf',
      fileweight: 'The file must be less than 2 MB.',
    },
    archivohint: 'Only .PDF file is allowed.',
    archivoActulizar: 'Update document',
    open: 'Open',
    vigente: 'Current',
    proceso: 'In Process',
    vencida: 'Expired',
    fechaVigencia: 'Residence Validity Date',
    ENERO: 'JANUARY',
    FEBRERO: 'FEBRUARY',
    MARZO: 'MARCH',
    ABRIL: 'APRIL',
    MAYO: 'MAY',
    JUNIO: 'JUNE',
    JULIO: 'JULY',
    AGOSTO: 'AUGUST',
    SEPTIEMBRE: 'SEPTEMBER',
    OCTUBRE: 'OCTOBER',
    NOVIEMBRE: 'NOVEMBER',
    DICIEMBRE: 'DECEMBER',
    WEBSITE: 'WEBSITE',
    FACEBOOK: 'FACEBOOK',
    INSTAGRAM: 'INSTAGRAM',
    LINKEDIN: 'LINKEDIN',
    REFERRALS: 'REFERRALS',
    OTHERS: 'OTHERS',
    INICIAL: 'BASIC',
    INTERMEDIO: 'INTERMEDIATE',
    AVANZADO: 'ADVANCED',
    NATIVO: 'NATIVE',
    RENUNCIA: 'RESIGNATION',
    DESAHUCIO: 'DISMISSAL',
    REDUCCION: 'STAFF REDUCTION',
    atras: 'Back',
    cancelar: 'Cancel',
    continuar: 'Next',
    enviar: 'Send',
    primaria: 'Primary',
    secundaria: 'Secondary',
    tecnico: 'Higher Technical',
    universitaria: 'University',
    postGrado: 'Postgraduate',
    maestria: 'Master',
    doctorado: 'Doctorate',
    diplomado: 'Diploma/Certification',
    curso: 'Course',
    otro: 'Other',
  },
};
