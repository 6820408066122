/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
// UppercaseDirective.ts
import { Directive } from 'vue';

interface ElementWithUppercaseCleanup extends HTMLElement {
  _uppercaseCleanup?: {
    handler: (event: Event) => void;
  };
}

const UppercaseDirective: Directive<ElementWithUppercaseCleanup> = {
  mounted(el) {
    const inputHandler = (event: Event) => {
      if (!(event.target instanceof HTMLInputElement)) return;

      const target = event.target as HTMLInputElement;
      if (!target.value) return;

      target.value = target.value.toUpperCase();
      target.dispatchEvent(new Event('input'));
    };

    el.addEventListener('input', inputHandler);

    // Utilizamos un objeto para mantener la referencia al event listener
    el._uppercaseCleanup = {
      handler: inputHandler,
    };
  },

  unmounted(el) {
    const cleanup = el._uppercaseCleanup;
    if (cleanup && cleanup.handler) {
      el.removeEventListener('input', cleanup.handler);
      delete el._uppercaseCleanup;
    }
  },
};

export default UppercaseDirective;
