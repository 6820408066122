import { createRouter, createWebHistory } from 'vue-router';
import generatedRoutes from 'virtual:generated-pages'; // Rutas generadas automáticamente
import { setupLayouts } from 'virtual:generated-layouts'; // Función para configurar layouts

// Configurar los layouts en las rutas generadas
const routes = setupLayouts(generatedRoutes);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL), // Historia para el navegador
  routes, // Pasas el array de rutas procesadas
});

router.beforeEach((to, from, next) => {
  const appName = import.meta.env.VITE_APP_NAME;

  if (appName === 'GDL') {
    const defaultTitle = 'Portal de Candidatos - Grupo Diario Libre';
    document.title = defaultTitle;
    document.querySelector<HTMLLinkElement>('#favicon')?.setAttribute('href', '/favicon-gdl.ico');
  } else {
    const defaultTitle = 'Portal de Candidatos - Grupo Puntacana';
    document.title = defaultTitle;
    document.querySelector<HTMLLinkElement>('#favicon')?.setAttribute('href', '/favicon.ico');
  }

  next();
});

export default router;
